import React, { Component } from 'react';
import cookie from 'react-cookies';
import CookieConsent from "react-cookie-consent";

import scrollToComponent from 'react-scroll-to-component';

import './css/App.css';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, 
  Nav, NavItem, NavLink } from 'reactstrap';

import PartImage from './PartImage.js';
import PartMain from './PartMain.js';
import PartContacts from './PartContacts.js';
import PartFooter from './PartFooter.js';

import logo from './images/BODY.png';

class App extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  componentWillMount() {
    this.setState({
      lang: cookie.load('lang')
    });
  }
  
  setLangSk() {
    cookie.save('lang', 'sk', { path: '/' });
    window.location.reload();
  }
  
  setLangCz() {
    cookie.save('lang', 'cz', { path: '/' });
    window.location.reload();
  }
  
  setLangRu() {
    cookie.save('lang', 'ru', { path: '/' });
    window.location.reload();
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    var translations;
    if(this.state.lang === undefined)
    {
      const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
      const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
      if(languageWithoutRegionCode === 'sk' || languageWithoutRegionCode === 'cz' || languageWithoutRegionCode === 'ru')
        translations = require('./lang/' + languageWithoutRegionCode + '.json');
      else {
        translations = require('./lang/sk.json');
        cookie.save('lang', 'sk', { path: '/' })
      }
    }
    else {
      translations = require('./lang/' + this.state.lang + '.json');
    }

    return (
      <div className="App">
      {/* <div className='home logoContainer' ref={(section) => { this.Home = section; }}>
        <PartLogo />
      </div> */}
        <Navbar color="white" light expand="md" sticky="top">
        <NavbarBrand href="#home">
            <img src={logo} alt="logo" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink onClick={() => {scrollToComponent(this.Home, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#home">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {scrollToComponent(this.Contact, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#contact">{translations.p8.Text7}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {this.toggle(); this.setLangSk()}} className="cursorHand">sk</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {this.toggle(); this.setLangCz()}} className="cursorHand">cz</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {this.toggle(); this.setLangRu()}} className="cursorHand">ru</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <CookieConsent location="bottom" buttonText={translations.p11.Text12}
            cookieName="siteCookie" style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }} expires={150}
        >
          {translations.p11.Text11}
        </CookieConsent>
        <PartImage />
        <div className='home' ref={(section) => { this.Home = section; }}>
          <PartMain />
        </div>
        <div className='contact' ref={(section) => { this.Contact = section; }}>
          <PartContacts />
        </div>
          <PartFooter />
      </div>
    );
  }
}

export default App;
