import React, { Component } from 'react';
import cookie from 'react-cookies';

import { Container, Row, Col } from 'reactstrap';

import * as FontAwesome from 'react-icons/lib/fa';

class PartContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    this.setState({
      lang: cookie.load('lang')
    });
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    let heightTmp = window.innerWidth * 0.6;
    this.setState({ width: window.innerWidth, height: heightTmp });
  }

  render() {
    var translations;
    if(this.state.lang === undefined)
    {
      const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
      const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
      if(languageWithoutRegionCode === 'sk' || languageWithoutRegionCode === 'cz' || languageWithoutRegionCode === 'ru')
        translations = require('./lang/' + languageWithoutRegionCode + '.json');
      else {
        translations = require('./lang/sk.json');
        cookie.save('lang', 'sk', { path: '/' })
      }
    }
    else {
      translations = require('./lang/' + this.state.lang + '.json');
    }

    return (
      <div className="contactContainer" id="contact" name="contact">
      <Container>
        <Row>
          <Col md="12">
            <h1>{translations.p7.Headline}</h1>
            <br />
            <p>{translations.p7.Intro1}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <img src='../images/pageContact.png' alt={translations.p7.Headline}/>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h1>LIO.company</h1>
          </Col>
          <Col md="12" style={{padding: '0 0 1em 0'}}>
            <p><FontAwesome.FaEnvelope /> <a href="mailto:info@lio.company">info@lio.company</a></p>
          </Col>
        </Row>
        <Row>
          <Col style={{padding: '1em 0 0 0'}}>
            <p><b>Slovenská republika</b></p>
            <p>Miletičova 44</p>
            <p>821 08, Bratislava II</p>
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default PartContact;