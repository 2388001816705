import React, { Component } from 'react';
import cookie from 'react-cookies';

import { Container, Row, Col } from 'reactstrap';

import * as FontAwesome from 'react-icons/lib/fa';

import * as translations from './lang/sk.json';

class PartMain extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
      width: 0,
      heightSet: true,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    this.setState({
      lang: cookie.load('lang')
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    let heightTmp = window.innerWidth * 0.6;
    this.setState({ width: window.innerWidth, height: heightTmp });
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    var translations;
    if (this.state.lang === undefined) {
      const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
      const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
      if (languageWithoutRegionCode === 'sk' || languageWithoutRegionCode === 'cz') {
        translations = require('./lang/' + languageWithoutRegionCode + '.json');
      } else if (languageWithoutRegionCode === 'ru') {
        translations = require('./lang/' + languageWithoutRegionCode + '.json');
      } else {
        translations = require('./lang/sk.json');
        cookie.save('lang', 'sk', { path: '/' })
      }
    }
    else {
      if (this.state.lang === 'sk' || this.state.lang === 'cz') {
        translations = require('./lang/' + this.state.lang + '.json');
      } else if (this.state.lang === 'ru') {
        translations = require('./lang/' + this.state.lang + '.json');
      }
    }

    return (
      <div className="textContainer">
        <Container>
          <Row>
            <Col md="12">
              <h3>{translations.p1.Text1}</h3>
            </Col>
            <Col md="4">
              <FontAwesome.FaShareAlt size={40} />
              <br />
              {translations.p1.Text2}
            </Col>
            <Col md="4">
              <FontAwesome.FaBellO size={40} />
              <br />
              {translations.p1.Text3}
            </Col>
            <Col md="4">
              <FontAwesome.FaBalanceScale size={40} />
              <br />
              {translations.p1.Text4}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h3>{translations.p1.Text5}</h3>
            </Col>
            <Col md="4">
              <FontAwesome.FaCloud size={40} />
              <br />
              {translations.p1.Text6}
            </Col>
            <Col md="4">
              <FontAwesome.FaJsfiddle size={40} />
              <br />
              {translations.p1.Text7}
            </Col>
            <Col md="4">
              <FontAwesome.FaClockO size={40} />
              <br />
              {translations.p1.Text8}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PartMain;