import React, { Component } from 'react';
import cookie from 'react-cookies';

import { Container, Row, Col } from 'reactstrap';

import * as translations from './lang/sk.json';

class PartMain extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
      width: 0,
      heightSet: true,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    this.setState({
      lang: cookie.load('lang')
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    let heightTmp = window.innerWidth * 0.6;
    this.setState({ width: window.innerWidth, height: heightTmp });
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    var translations;
    if (this.state.lang === undefined) {
      const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
      const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
      if (languageWithoutRegionCode === 'sk' || languageWithoutRegionCode === 'cz') {
        translations = require('./lang/' + languageWithoutRegionCode + '.json');
      } else if (languageWithoutRegionCode === 'ru') {
        translations = require('./lang/' + languageWithoutRegionCode + '.json');
      } else {
        translations = require('./lang/sk.json');
        cookie.save('lang', 'sk', { path: '/' })
      }
    }
    else {
      if (this.state.lang === 'sk' || this.state.lang === 'cz') {
        translations = require('./lang/' + this.state.lang + '.json');
      } else if (this.state.lang === 'ru') {
        translations = require('./lang/' + this.state.lang + '.json');
      }
    }

    return (
      <div className="imageContainer">
      <img src='../images/lio.jpg' className="col-md-12" alt={translations.p1.Headline} />
      </div>
    );
  }
}

export default PartMain;